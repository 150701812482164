import React, {Component} from 'react';
import {  Row } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';

import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody } from 'reactstrap';
import { auth } from '../../actions/auth';

class UserVarification extends Component {
   constructor(props) {
     super(props);
     this.timer = 0;
     this.state = {
        accessToken: null,
        isOtpInputShow : false,
        timeCounter: 30,
    };
   }

  

   addUtmTracking = async (e) => {
        e.preventDefault();
        let { user_name, user_mobile, otp } = e.target;
        let { utmCode, setLoader, setError } = this.props;
        setLoader(true);
        try{
            if(!otp){
                await auth.user.login({mobile: user_mobile.value, feature: !window.location.hostname.includes("pickmywork") ? "MT" : "UTM"});
                this.setState({isOtpInputShow : true});
                this.timer = setInterval(this.countDown, 1000);
                setError({showBar: true, message: "OTP sent to Mobile", isSuccess: true})
                return  setLoader(false);
            }
            var body = {
                mobile: user_mobile.value, 
                user_name: user_name.value, 
                otp: otp.value, 
                utm_code: utmCode
            }
            var resp = await auth.Utm.addUTMAuth(body);
            var token = resp.data.data.access_token;
            token = utmCode+"_"+token;
            this.props.setAccessToken(token);
            localStorage.setItem('utmToken', token);
            this.setState({isOtpInputShow: false})
        }
        catch(e){
            setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        setLoader(false);
   }

   resendOtp = async () => {
    let { setLoader, setError } = this.props;
    setLoader(true);
    try{
        var elem = document.getElementById("mobile_no");
        if(elem){
            await auth.user.login({mobile: elem.value, feature: !window.location.hostname.includes("pickmywork") ? "MT" : "UTM"});
            this.setState({isOtpInputShow : true});
            this.timer = setInterval(this.countDown, 1000);
            setError({showBar: true, message: "OTP sent to Mobile", isSuccess: true})
        }
    }
    catch(e){
        setError({showBar: true, message: e.response && e.response.data ? e.response.data.message : e.toString()});
    }
    setLoader(false);
   }

   countDown = () => {
    let timeCounter = this.state.timeCounter ? this.state.timeCounter - 1 : 29;
    if (timeCounter == 0) clearInterval(this.timer);
    this.setState({ timeCounter });
  }
   render() {
    let {isOtpInputShow, timeCounter} = this.state;
   let { userProfile } = this.props;
    return (

            
        <form onSubmit={this.addUtmTracking}>
            <Card className="w-100 my-2" style={{background: "#eeeeee80", borderRadius: 8, borderColor: "#eee"}}>
                <CardBody className="px-3 py-2 text-left" >
                    <Row className="my-3 align-items-center">
                        <Col xs={7} className="text-left px-4">
                            <h4 className='text-secondary' style={{fontWeight: 600}}>Get the best offers from our Trusted Partner</h4>
                            <h4 className='' style={{}}>{userProfile.first_name}</h4>
                        </Col>
                        <Col xs={5}>
                            <img src={"/Image/utm_link1.png"} style={{width: "100%"}} />
                        </Col>
                    </Row>
                    <strong className='d-block my-2 text-muted' style={{fontSize: 14}}>Your Name</strong>
                    <InputFeild minLength={3} required name="user_name" placeholder="Enter Your Name" />
                    <strong className='d-block my-2 text-muted' style={{fontSize: 14}}>Mobile Number</strong>
                    <InputFeild id="mobile_no" required minLength={10} maxLength={10} pattern="\d*" name="user_mobile" placeholder="Enter Your Number" />
                    { !!isOtpInputShow && 
                    <Row>
                        <Col xs={12}>
                            <strong className='d-block my-2 text-muted' style={{fontSize: 14}}>Verification OTP</strong>
                            <InputFeild required id="otp_input" name="otp" placeholder="Enter 4 Digit OTP recieved on mobile" />
                        </Col>
                        <Col xs={12} className={"text-right text-danger"}>{timeCounter > 0 ? "Resent OTP in 0:"+timeCounter : <Button type="button" onClick={() => this.resendOtp()} color="link">Resend OTP</Button>}</Col>
                    </Row>
                    }
                    <Button className="mb-3 mt-4 w-100" type="submit" color="danger" >{!isOtpInputShow? "Get Verification OTP" : <>Proceed to Next &#10148;</>}</Button>
                </CardBody>
            </Card>
        </form>

    );
 }
}

export default UserVarification;
