
import React, { Component } from 'react';
import { FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputFeild from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal, Card, CardBody, Collapse } from 'reactstrap';
import { auth as Auth } from '../../../actions/auth';
import CONSTANTS from '../../../constant';
import moment from 'moment';
import { downloadApiFile } from '../../../actions';
import { setError, setLoader } from '../../../actions/action-creator';
import store from '../../../store';
import PartnerPersonalDetails from './personal_details';
import PartnerDocuments from './documents';

class PartnerProfile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0
        };
    }


    componentDidMount() {
      
    }




    render() {
        let { } = this.props;
        let { selectedTab } = this.state;

        return (

            <>
                <h4 className='mt-2 mb-5'>Profile Details</h4>
                <Row className="mt-4">
                    {["Personal Details", "Documents"].map((tab, i) =>
                    <Col xs={12} md="auto" key={i} className="">
                        <div style={{borderRadius: 18, cursor: "pointer", background: selectedTab === i ? "#4a58d545" : "#f8f9fa"}} className='px-4 py-2 border font-weight-bold' onClick={() => this.setState({selectedTab: i})}>{tab}</div>
                    </Col>
                    )}
                </Row>
                <div className='p-3 mt-4'>
                    {selectedTab === 0 && <PartnerPersonalDetails />}
                    {selectedTab === 1 && <PartnerDocuments />}
                </div>
            </>

        );
    }
}

export default PartnerProfile;
