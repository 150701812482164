
import React, {Component} from 'react';
import {  FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import InputField from 'reactstrap/lib/Input';
import { ModalBody, ModalHeader, ModalFooter, Modal ,Card, CardBody , Collapse } from 'reactstrap';
import { auth as Auth } from '../../actions/auth';
import CONSTANTS from '../../constant';
import moment from 'moment';
import { setLoader } from '../../actions/action-creator';
import store from '../../store';

class PartnerLogin extends Component {
   constructor(props) {
     super(props);
     this.state = {
       mobile: "",
       isOtpSent: false,
       otp: ""
    };
   }

   
    componentDidMount(){
    }
   
    login = async (e) => {
        e.preventDefault();
        let { setError } = this.props;
        let { mobile, isOtpSent, otp } = this.state;
        store.dispatch(setLoader(true));
        try{
            if(isOtpSent){
                let resp = await Auth.Partner.loginOtp({ mobile, otp });
                this.props.checkSession();
            }
            else{
                let resp = await Auth.Partner.sendOtp({ mobile });
                this.setState({ isOtpSent: true });
            }
        }
        catch(e){
            setError({showBar: true, message:  e.response && e.response.data ? e.response.data.message : e.toString()});
        }
        store.dispatch(setLoader(false));
    }

    


   render() {
       let  { } = this.props;
        let { mobile, otp, isOtpSent } = this.state;
       
    return (

        <>
            <Row className="align-items-center justify-content-center" style={{minHeight: "80vh"}}>
                <Col xs={10} md={4}>
                    <div className='w-100 p-4 border text-center border-light shadow'>
                        <form onSubmit={this.login}>
                            <h5>Login to Partners Panel</h5>
                            <InputField required className="my-5" placeholder="Enter Mobile Number" value={mobile} onChange={(e) => this.setState({ mobile: e.target.value })} />
                            {isOtpSent && <InputField required className="my-5" placeholder="Enter OTP" value={otp} onChange={(e) => this.setState({ otp: e.target.value })} />}
                            <Button color="primary" style={{background: "#4a58d5", width: "50%"}}>{isOtpSent? "Submit OTP" : "Login"}</Button>
                        </form>
                    </div>
                </Col>
            </Row>
        </>

    );
 }
}

export default PartnerLogin;
